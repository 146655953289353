<template>
    <CardComponent
        :title="title"
        :icon="icon"
        class="task-finish-condition-card"
    >
        <template v-slot:cardOptions>
            <el-date-picker
                v-model="conditionMouth"
                type="month"
                :clearable="false"
                value-format="yyyy-M"
                format="yyyy-MM"
                placeholder="选择月"
                @change="handleMonthChange"
            >
            </el-date-picker>
        </template>
        <div class="'task-finish-condition-main'">
            <el-table
                v-loading="loading"
                class="task-finish-condition-table"
                :data="tableData"
                row-key="groupId"
                height="3.4rem"
                style="width: 100%;"
                default-expand-all
                :indent="0"
            >
                <el-table-column
                    prop="groupName"
                    show-overflow-tooltip
                    label="项目达成"
                    align="left"
                    min-width="100"
                >
                </el-table-column>
                <el-table-column
                    v-for="column in columns"
                    :key="column"
                    :label="column"
                    align="right"
                >
                    <template slot-scope="scope">
                        <div class="cloumn-with-progress">
                            <span>{{ handleRowData(scope.row, column) }}</span>
                            <el-progress
                                :text-inside="true"
                                :stroke-width="10"
                                :percentage="
                                    Number(
                                        handleRowData(scope.row, column, 'rate')
                                    )
                                "
                                :color="
                                    diffColor(
                                        Number(
                                            handleRowData(
                                                scope.row,
                                                column,
                                                'rate'
                                            )
                                        )
                                    )
                                "
                            ></el-progress>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import accountSvc from 'services/accounting'
import { makeDataToTree } from '@/utils'
import { moneyFilter } from '@/utils/index'
export default {
    name: '',
    components: { CardComponent },
    props: {},
    data () {
        return {
            title: '目标达成',
            icon: 'iconfont icon-kucunjiankong',
            tableData: [],
            conditionMouth: new Date(),
            loading: false,
            columns: [],
            NOs: ['XT000002', 'XT000004', 'XT000011', 'XT000006']
        }
    },
    created () {
        this.goalAttainment()
    },
    methods: {
        handleMonthChange () {
            this.goalAttainment()
        },
        async goalAttainment () {
            console.log(this.conditionMouth)
            try {
                this.loading = true
                const { data = [] } = await accountSvc.goalAttainment({
                    dataYear: new Date(this.conditionMouth).getFullYear(),
                    dataMonth: new Date(this.conditionMouth).getMonth() + 1
                })
                if (
                    data.length > 0 &&
                    data[0].list &&
                    data[0].list.length > 0
                ) {
                    data[0].list.forEach((item, index) => {
                        this.columns[index] = data[0].list.filter(
                            (o) => o.accountingItemNo === this.NOs[index]
                        )[0].accountingItemTitle
                    })
                }
                // this.tableData = data
                this.tableData = makeDataToTree(data, 'parentId', 'groupId')
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        },
        // 处理表格数据
        handleRowData (row, propName, filedName = 'value') {
            let value = 0
            if (row.list && row.list.length > 0) {
                const res = row.list.filter((item) => {
                    return item.accountingItemTitle == propName
                })
                if (res.length > 0) {
                    if (filedName === 'value') {
                        value = moneyFilter(res[0][filedName])
                    }
                    if (filedName === 'rate') {
                        value = Number(res[0][filedName]).toFixed(2)
                    }
                }
            }
            return value
        },
        diffColor (val) {
            let color = ''
            if (val <= 20) {
                color = '#DF5A68'
            } else if (val > 20 && val <= 50) {
                color = '#e6a23c'
            } else {
                color = '#52C123'
            }
            return color
        }
    }
}
</script>
<style lang="less" scoped>
.task-finish-condition {
    &-card {
        grid-row-start: span 2;
        grid-column-start: span 3;

        /deep/ .main {
            padding: 0;

            .com-card-header {
                padding: 0.2rem;
            }
        }
    }

    &-main {
        padding: 0 0.2rem;
    }

    &-main_small {
        padding: 0 0.1rem;
    }

    /deep/ &-table {
        font-size: 0.14rem;
        color: #606266;

        thead {
            .cell {
                font-size: 0.14rem;
                font-weight: 400;
                color: #a7b6c3;
            }
        }

        tr,
        th,
        td {
            // background-color: #f2f5f9;
            border: none;
        }

        .el-table__body {
            td {
                color: #606266;
            }
        }
        // .el-table__expand-icon {
        //   .el-icon-arrow-right:before {
        //     // 这是收起图标
        //     content: "\e791";
        //   }
        // }
        .el-table__expand-icon {
            font-size: 16px;
            color: #c0c4cc;

            .el-icon-arrow-right::before {
                // 这是展开图标
                content: '\e791';
            }
        }

        .cell .cloumn-with-progress {
            display: flex;
            align-items: center;
            justify-content: center;

            > span:nth-child(1) {
                flex: 1;
                overflow: hidden;
                text-align: right;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .el-progress {
                display: flex;
                min-width: 40px;
                height: 10px;
                margin-left: 5px;

                .el-progress-bar__outer {
                    background-color: #c0c4cc;
                }

                .el-progress-bar__inner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-width: 100% !important;
                }

                .el-progress-bar__innerText {
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: 0 0 0 2px;
                    transform: scale(0.7);
                }
            }
        }
    }

    /deep/ &-table::before {
        height: 0 !important;
    }
}
</style>
